<template>
    <div class="modal fade" id="modalContact" tabindex="-1" role="dialog" aria-labelledby="modalContactTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="button-close" @click="close()"></div>
                <form @submit.prevent="formController($event,urlCreate)">
                    <div class="modal-body">
                        <div class="title">
                            <span>CONVERSEMOS</span>
                        </div>
                        <fieldset class="form-group">
                            <label for="name">Nombre completo</label>
                            <input type="text" name="name" class="form-control" v-model="model.name" placeholder="Ex: John Doe Arsyad">
                            <div class="invalid-feedback name-errors"></div>
                        </fieldset>
                        <fieldset class="form-group">
                            <label for="email">Email</label>
                            <input type="text" name="email" class="form-control" v-model="model.email" placeholder="Ex: john.doe@gmail.com">
                            <div class="invalid-feedback email-errors"></div>
                        </fieldset>
                        <fieldset class="form-group">
                            <label for="phone">Teléfono</label>
                            <input type="text" name="phone" class="form-control" v-model="model.phone" placeholder="Ex: 999999888">
                            <div class="invalid-feedback phone-errors"></div>
                        </fieldset>
                        <fieldset class="form-group">
                            <label for="category">Categoría</label>
                            <select 
                                name="category"
                                class="form-control"
                                v-model="model.category"
                            >
                                <option value="" hidden>Seleccionar</option>
                                <option value="tomorrow">Servicio para mañana</option>
                                <option value="week">Servicio en la semana</option>
                                <option value="custom">Servicio personalizado</option>
                                <option value="others">Otros</option>
                            </select>
                            <div class="invalid-feedback category-errors"></div>
                        </fieldset>
                        <fieldset class="form-group">
                            <textarea 
                                name="content" 
                                class="form-control"
                                rows="5"
                                placeholder="Escríbenos..."
                                v-model="model.content"
                            >
                            </textarea>
                            <div class="invalid-feedback content-errors"></div>
                        </fieldset>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import formController from "../../../../general/js/mixins/formController";
    export default {
        mixins: [ formController ],
        props: {
            urlCreate: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                model: {
                    name: '',
                    email: '',
                    phone: '',
                    category: '',
                    content: ''
                }
            }
        },
        created() {
            window.bus.$on('openContactModal', (data) => {
                if(data) {
                    this.model.category = data;
                }
                $('#modalContact').modal('show');
            });
        },
        mounted() {
            $('#modalContact').on('hidden.bs.modal', (e) => {
                this.clearModel();
            })

            window.bus.$on('clearForm',() => {
                this.close();
            });
        },
        methods: {
            close: function() {
                $('#modalContact').modal('hide');
            },
            clearModel: function() {
                this.model = {
                    name: '',
                    email: '',
                    phone: '',
                    category: '',
                    content: ''
                }
            }
        },
    }
</script>

<style>

</style>